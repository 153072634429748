var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isTicketDetails
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _vm.ticketSuccessAlert && !_vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            dismissible: "",
                            "close-icon": "mdi-close",
                            text: "",
                            icon: "mdi-check-circle-outline",
                            dense: "",
                            type: "success",
                          },
                          on: { input: _vm.closeTicketAlert },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "my-auto font-weight-regular text-subtitle-2",
                            },
                            [_vm._v(_vm._s(_vm.ticketAlertMessage))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "projects" } } },
                            [
                              _c(
                                "v-btn",
                                { staticClass: "mt-2", attrs: { plain: "" } },
                                [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "grey--text text--darken-4 text-h5 font-weight-medium mt-3",
                            },
                            [_vm._v(_vm._s(_vm.projectDetails.project_name))]
                          ),
                          _c(
                            "v-chip",
                            {
                              staticClass: "mt-4 ml-3",
                              attrs: { small: "", color: "info" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.projectDetails.status) + " "
                              ),
                            ]
                          ),
                          _vm.ticketSuccessAlert &&
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex justify-end align-center pt-2",
                                },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        dismissible: "",
                                        "close-icon": "mdi-close",
                                        text: "",
                                        icon: "mdi-check-circle-outline",
                                        dense: "",
                                        type: "success",
                                      },
                                      on: { input: _vm.closeTicketAlert },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "my-auto font-weight-regular text-subtitle-2",
                                        },
                                        [_vm._v(_vm._s(_vm.ticketAlertMessage))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$vuetify.breakpoint.mdAndUp &&
                          !_vm.ticketSuccessAlert
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "ml-8 mt-1 d-flex justify-end pt-0",
                                },
                                [
                                  _vm.checkPermission("associateTicket") &&
                                  _vm.currentTab === "tickets"
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "font-weight-regular white--text text-none rounded-lg d-flex justify-end mt-2 mr-4",
                                          attrs: {
                                            color: "primary",
                                            outlined: "",
                                          },
                                          on: {
                                            click:
                                              _vm.openAssociateTicketDialog,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("associateTicket"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "font-weight-regular white--text text-none rounded-lg d-flex justify-end mt-2",
                                      attrs: { color: "primary" },
                                      on: { click: _vm.createTicket },
                                    },
                                    [
                                      _c("v-icon", [_vm._v("mdi-plus")]),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("createTicket"))
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "pl-4 mt-4",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "ml-16", attrs: { cols: "5" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "grey--text text--darken-4 text-h6 font-weight-medium",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.projectDetails.progress) + "% "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-1 text-caption ml-3 mt-3",
                                    },
                                    [_vm._v("Completed")]
                                  ),
                                ]
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-progress-linear", {
                                    attrs: {
                                      height: "12",
                                      rounded: "",
                                      value: _vm.projectDetails.progress,
                                      color: "green",
                                      "buffer-value": "100",
                                      bottom: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { elevation: "0" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            { staticClass: "pa-0" },
                                            [
                                              _c(
                                                "v-container",
                                                { staticClass: "pa-0 mt-4" },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "ml-1 my-2",
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "projectName"
                                                              )
                                                            ) + ":"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 pr-4 grey--text text--darken-4 text-body-2 font-weight-medium",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .projectDetails
                                                                  .project_name
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "ml-1 my-2",
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "projectOwner"
                                                              )
                                                            ) + ":"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.projectOwner &&
                                                                  _vm
                                                                    .projectOwner
                                                                    .name
                                                                  ? _vm
                                                                      .projectOwner
                                                                      .name
                                                                  : ""
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "ml-1 my-2",
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "startDate"
                                                              )
                                                            ) + ":"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .projectDetails
                                                                  .start_date
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { elevation: "0" },
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            { staticClass: "pa-0" },
                                            [
                                              _c(
                                                "v-container",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "ml-1 my-2",
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                          attrs: { cols: "6" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("tasks")
                                                            ) + ":"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .projectDetails
                                                                  .tasks
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "ml-1 my-2",
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                          attrs: { cols: "6" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "createdOnText"
                                                              )
                                                            ) + ":"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.formatDate(
                                                                  _vm
                                                                    .projectDetails
                                                                    .created_at
                                                                )
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "ml-1 my-2",
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                          attrs: { cols: "6" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "latestFinishDate"
                                                              )
                                                            ) + ":"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .projectDetails
                                                                  .finish_date
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    plain: "",
                                    width: "50",
                                    "min-width": "40",
                                  },
                                  on: { click: _vm.showUploadFiles },
                                },
                                [_c("v-icon", [_vm._v("mdi-content-copy")])],
                                1
                              ),
                              _vm.checkPermission("editProject")
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        plain: "",
                                        width: "50",
                                        "min-width": "40",
                                        disabled:
                                          _vm.projectDetails.status ===
                                          "Inactive",
                                      },
                                      on: { click: _vm.onEditProjectClicked },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-pencil-outline"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-app-bar",
                            {
                              staticClass: "appbar-header",
                              attrs: {
                                elevation: "0",
                                color: "white",
                                height: "40",
                              },
                            },
                            [
                              _c(
                                "v-tabs",
                                {
                                  model: {
                                    value: _vm.currentTab,
                                    callback: function ($$v) {
                                      _vm.currentTab = $$v
                                    },
                                    expression: "currentTab",
                                  },
                                },
                                _vm._l(_vm.tabs, function (key, index) {
                                  return _c(
                                    "v-tab",
                                    {
                                      key: index,
                                      staticClass: "text-none",
                                      class: _vm.$vuetify.breakpoint.mdAndUp
                                        ? "pl-8"
                                        : "pl-5",
                                      attrs: { href: "#" + key },
                                    },
                                    [_vm._v(_vm._s(_vm.$t(key)))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.currentTab == "tasks" && _vm.projectId
                        ? _c(
                            "div",
                            { staticClass: "mt-4" },
                            [
                              _c("ProjectTask", {
                                attrs: {
                                  "project-detail": _vm.projectDetails,
                                  "project-id": _vm.projectId,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentTab == "board"
                        ? _c(
                            "div",
                            { staticClass: "mt-4" },
                            [
                              _c("KanbanBoard", {
                                attrs: {
                                  "project-details": _vm.projectDetails,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentTab == "tickets"
                        ? _c(
                            "div",
                            { staticClass: "mt-4" },
                            [
                              _c("ProjectTicketTable", {
                                attrs: {
                                  "project-flag": true,
                                  "project-details": _vm.projectDetails,
                                },
                                on: {
                                  openAssociateTicketDialog:
                                    _vm.openAssociateTicketDialog,
                                  onClickTicket: _vm.onClickTicket,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "v-dialog",
                    {
                      attrs: { fullscreen: "" },
                      model: {
                        value: _vm.dialog,
                        callback: function ($$v) {
                          _vm.dialog = $$v
                        },
                        expression: "dialog",
                      },
                    },
                    [
                      _c("v-card", [
                        _c(
                          "div",
                          { staticClass: "pt-2" },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "projects" } } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mt-2",
                                        attrs: { plain: "" },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-arrow-left"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "d-flex justify-end" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          plain: "",
                                          width: "50",
                                          "min-width": "40",
                                        },
                                        on: { click: _vm.showUploadFiles },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-content-copy"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm.checkPermission("editProject")
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              plain: "",
                                              width: "50",
                                              "min-width": "40",
                                              disabled:
                                                _vm.projectDetails.status ===
                                                "Inactive",
                                            },
                                            on: {
                                              click: _vm.onEditProjectClicked,
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-pencil-outline"),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                staticClass: "pl-5 pr-4",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "grey--text text--darken-4 text-h5 font-weight-medium mt-3",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.projectDetails.project_name)
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "d-flex justify-end" },
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "mt-4 ml-3",
                                        attrs: { small: "", color: "info" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.projectDetails.status) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                staticClass: "pl-9 pr-6 mt-4 pb-4",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "grey--text text--darken-4 text-h6 font-weight-medium",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.projectDetails.progress) +
                                            "% "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "grey--text text--darken-1 text-caption ml-3 mt-3",
                                          },
                                          [_vm._v("Completed")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-progress-linear", {
                                          attrs: {
                                            height: "12",
                                            rounded: "",
                                            value: _vm.projectDetails.progress,
                                            color: "green",
                                            "buffer-value": "100",
                                            bottom: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              { attrs: { elevation: "0" } },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "pa-0" },
                                                  [
                                                    _c(
                                                      "v-container",
                                                      {
                                                        staticClass:
                                                          "pa-0 mt-4",
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "ml-1 my-2",
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                                attrs: {
                                                                  cols: "5",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "projectName"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-0 pr-4 grey--text text--darken-4 text-body-2 font-weight-medium",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .projectDetails
                                                                        .project_name
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "ml-1 my-2",
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                                attrs: {
                                                                  cols: "5",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "tasks"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .projectDetails
                                                                        .tasks
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "ml-1 my-2",
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                                attrs: {
                                                                  cols: "5",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "projectOwner"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.projectOwner &&
                                                                        _vm
                                                                          .projectOwner
                                                                          .name
                                                                        ? _vm
                                                                            .projectOwner
                                                                            .name
                                                                        : ""
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "ml-1 my-2",
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                                attrs: {
                                                                  cols: "5",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "createdOnText"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.formatDate(
                                                                        _vm
                                                                          .projectDetails
                                                                          .created_at
                                                                      )
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "ml-1 my-2",
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                                attrs: {
                                                                  cols: "5",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "startDate"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .projectDetails
                                                                        .start_date
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "ml-1 my-2",
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                                attrs: {
                                                                  cols: "5",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "latestFinishDate"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .projectDetails
                                                                        .finish_date
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-spacer"),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-app-bar",
                                  {
                                    staticClass: "appbar-header",
                                    attrs: {
                                      elevation: "0",
                                      color: "white",
                                      height: "40",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-tabs",
                                      {
                                        model: {
                                          value: _vm.currentTab,
                                          callback: function ($$v) {
                                            _vm.currentTab = $$v
                                          },
                                          expression: "currentTab",
                                        },
                                      },
                                      _vm._l(_vm.tabs, function (key) {
                                        return _c(
                                          "v-tab",
                                          {
                                            key: key,
                                            staticClass: "text-none",
                                            class: _vm.$vuetify.breakpoint
                                              .mdAndUp
                                              ? "pl-8"
                                              : "pl-5",
                                            attrs: { href: "#" + key },
                                          },
                                          [_vm._v(_vm._s(_vm.$t(key)))]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.currentTab == "tasks" && _vm.projectId
                              ? _c(
                                  "div",
                                  { staticClass: "mt-4" },
                                  [
                                    _c("ProjectTask", {
                                      attrs: {
                                        "project-detail": _vm.projectDetails,
                                        "project-id": _vm.projectId,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentTab == "board"
                              ? _c(
                                  "div",
                                  { staticClass: "mt-4" },
                                  [
                                    _c("KanbanBoard", {
                                      attrs: {
                                        "project-details": _vm.projectDetails,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentTab == "tickets"
                              ? _c(
                                  "div",
                                  { staticClass: "mt-4" },
                                  [
                                    _c("ProjectTicketTable", {
                                      attrs: {
                                        "project-flag": true,
                                        "project-details": _vm.projectDetails,
                                      },
                                      on: {
                                        openAssociateTicketDialog:
                                          _vm.openAssociateTicketDialog,
                                        onClickTicket: _vm.onClickTicket,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
              _vm.$vuetify.breakpoint.mdAndUp && _vm.isUploadFiles
                ? _c(
                    "div",
                    { attrs: { id: "addProjectPanel" } },
                    [
                      _c("UploadFiles", {
                        attrs: { "reference-id": _vm.projectId },
                        on: { hideUploadFiles: _vm.hideUploadFiles },
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-dialog",
                    {
                      attrs: { fullscreen: "" },
                      model: {
                        value: _vm.isUploadFiles,
                        callback: function ($$v) {
                          _vm.isUploadFiles = $$v
                        },
                        expression: "isUploadFiles",
                      },
                    },
                    [
                      _c("UploadFiles", {
                        attrs: { "reference-id": _vm.projectId },
                        on: {
                          hideUploadFiles:
                            _vm.hideUploassociateTicketDialogadFiles,
                        },
                      }),
                    ],
                    1
                  ),
              _c(
                "v-layout",
                [
                  _vm.$vuetify.breakpoint.mdAndUp && _vm.addProjectDialog
                    ? _c(
                        "div",
                        { attrs: { id: "addProjectPanel" } },
                        [
                          _c("AddProject", {
                            ref: "addProject",
                            attrs: { "project-details": _vm.projectDetails },
                            on: { hideProjectDialog: _vm.hideProjectDialog },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.$vuetify.breakpoint.mdAndUp && _vm.addProjectDialog
                    ? _c(
                        "v-dialog",
                        {
                          attrs: { fullscreen: "" },
                          model: {
                            value: _vm.addProjectDialog,
                            callback: function ($$v) {
                              _vm.addProjectDialog = $$v
                            },
                            expression: "addProjectDialog",
                          },
                        },
                        [
                          _c("AddProject", {
                            ref: "addProject",
                            attrs: { "project-details": _vm.projectDetails },
                            on: { hideProjectDialog: _vm.hideProjectDialog },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("AssociateTicketDialog", {
                attrs: {
                  "project-details": _vm.projectDetails,
                  dialog: _vm.associateTicketDialog,
                },
                on: {
                  closeDialog: _vm.closeDialog,
                  associateTicketSubmit: _vm.associateTicketSubmit,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isTicketDetails
        ? _c("TicketDetails", {
            staticClass: "mt-4",
            attrs: { "ticket-details": _vm.TicketData },
            on: {
              hideTicketDetails: _vm.hideTicketDetails,
              secondaryFnSuccessAlert: _vm.secondaryFnSuccessAlert,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }