import { render, staticRenderFns } from "./ProjectDetails.vue?vue&type=template&id=646ae5af&scoped=true&"
import script from "./ProjectDetails.vue?vue&type=script&lang=js&"
export * from "./ProjectDetails.vue?vue&type=script&lang=js&"
import style0 from "./ProjectDetails.vue?vue&type=style&index=0&id=646ae5af&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "646ae5af",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAlert,VAppBar,VBtn,VCard,VCardText,VChip,VCol,VContainer,VDialog,VIcon,VLayout,VProgressLinear,VRow,VSpacer,VTab,VTabs})


/* hot reload */
if (module.hot) {
  var api = require("N:\\diglogix\\diglogix-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('646ae5af')) {
      api.createRecord('646ae5af', component.options)
    } else {
      api.reload('646ae5af', component.options)
    }
    module.hot.accept("./ProjectDetails.vue?vue&type=template&id=646ae5af&scoped=true&", function () {
      api.rerender('646ae5af', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/workflow/project/ProjectDetails.vue"
export default component.exports