<template>
  <div>
    <div v-if="!isTicketDetails" class="mt-3">
      <v-row v-if="ticketSuccessAlert && !$vuetify.breakpoint.mdAndUp">
        <v-alert
          dismissible
          close-icon="mdi-close"
          text
          icon="mdi-check-circle-outline"
          dense
          @input="closeTicketAlert"
          type="success"
        >
          <span class="my-auto font-weight-regular text-subtitle-2">{{
            ticketAlertMessage
          }}</span>
        </v-alert>
      </v-row>

      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-row no-gutters>
          <router-link :to="{ name: 'projects' }">
            <v-btn plain class="mt-2"><v-icon>mdi-arrow-left</v-icon> </v-btn>
          </router-link>
          <span
            class="grey--text text--darken-4 text-h5 font-weight-medium mt-3"
            >{{ projectDetails.project_name }}</span
          >
          <v-chip small class="mt-4 ml-3" color="info">
            {{ projectDetails.status }}
          </v-chip>
          <v-col
            v-if="ticketSuccessAlert && $vuetify.breakpoint.mdAndUp"
            class="d-flex justify-end align-center pt-2"
          >
            <v-alert
              dismissible
              close-icon="mdi-close"
              text
              icon="mdi-check-circle-outline"
              dense
              @input="closeTicketAlert"
              type="success"
            >
              <span class="my-auto font-weight-regular text-subtitle-2">{{
                ticketAlertMessage
              }}</span>
            </v-alert>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp && !ticketSuccessAlert"
            class="ml-8 mt-1 d-flex justify-end pt-0"
          >
            <v-btn
              v-if="
                checkPermission('associateTicket') && currentTab === 'tickets'
              "
              color="primary"
              outlined
              @click="openAssociateTicketDialog"
              class="font-weight-regular white--text text-none rounded-lg d-flex justify-end mt-2 mr-4"
            >
              {{ $t("associateTicket") }}</v-btn
            >
            <v-btn
              color="primary"
              @click="createTicket"
              class="font-weight-regular white--text text-none rounded-lg d-flex justify-end mt-2"
              ><v-icon>mdi-plus</v-icon> {{ $t("createTicket") }}</v-btn
            ></v-col
          >
        </v-row>
        <v-row no-gutters class="pl-4 mt-4">
          <v-col cols="5" class="ml-16">
            <v-row class="grey--text text--darken-4 text-h6 font-weight-medium"
              >{{ projectDetails.progress }}%
              <span class="grey--text text--darken-1 text-caption ml-3 mt-3"
                >Completed</span
              ></v-row
            >
            <v-row>
              <v-progress-linear
                height="12"
                rounded
                :value="projectDetails.progress"
                color="green"
                buffer-value="100"
                bottom
              ></v-progress-linear>
            </v-row>
            <v-row>
              <v-col cols="6" class="pa-0">
                <v-card elevation="0">
                  <v-card-text class="pa-0">
                    <v-container class="pa-0 mt-4">
                      <v-row class="ml-1 my-2"
                        ><v-col
                          cols="5"
                          class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                          >{{ $t("projectName") }}:</v-col
                        ><v-col
                          class="pa-0 pr-4 grey--text text--darken-4 text-body-2 font-weight-medium"
                        >
                          {{ projectDetails.project_name }}</v-col
                        ></v-row
                      >
                      <v-row class="ml-1 my-2"
                        ><v-col
                          cols="5"
                          class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                          >{{ $t("projectOwner") }}:</v-col
                        ><v-col
                          class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
                        >
                          {{
                            projectOwner && projectOwner.name
                              ? projectOwner.name
                              : ""
                          }}</v-col
                        ></v-row
                      >
                      <v-row class="ml-1 my-2"
                        ><v-col
                          cols="5"
                          class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                          >{{ $t("startDate") }}:</v-col
                        ><v-col
                          class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
                        >
                          {{ projectDetails.start_date }}</v-col
                        >
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="pa-0" elevation="0">
                  <v-card-text class="pa-0">
                    <v-container class="pa-0">
                      <v-row class="ml-1 my-2">
                        <v-col
                          cols="6"
                          class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                          >{{ $t("tasks") }}:</v-col
                        ><v-col
                          class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
                        >
                          {{ projectDetails.tasks }}</v-col
                        ></v-row
                      >
                      <v-row class="ml-1 my-2"
                        ><v-col
                          cols="6"
                          class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                          >{{ $t("createdOnText") }}:</v-col
                        ><v-col
                          class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
                        >
                          {{ formatDate(projectDetails.created_at) }}</v-col
                        ></v-row
                      >
                      <v-row class="ml-1 my-2"
                        ><v-col
                          cols="6"
                          class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                          >{{ $t("latestFinishDate") }}:</v-col
                        ><v-col
                          class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
                        >
                          {{ projectDetails.finish_date }}</v-col
                        ></v-row
                      >
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-end">
            <!-- <v-btn plain width="50" min-width="40" @click="openProjectInfoDialog"
            ><v-icon>mdi-alert-circle-outline</v-icon></v-btn
          > -->
            <v-btn plain width="50" min-width="40" @click="showUploadFiles"
              ><v-icon>mdi-content-copy</v-icon></v-btn
            >
            <v-btn
              v-if="checkPermission('editProject')"
              plain
              width="50"
              min-width="40"
              :disabled="projectDetails.status === 'Inactive'"
              @click="onEditProjectClicked"
              ><v-icon>mdi-pencil-outline</v-icon></v-btn
            >
          </v-col></v-row
        >
        <v-row>
          <v-app-bar
            elevation="0"
            color="white"
            class="appbar-header"
            height="40"
          >
            <v-tabs v-model="currentTab">
              <v-tab
                v-for="(key, index) in tabs"
                :key="index"
                class="text-none"
                :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-5'"
                :href="`#${key}`"
                >{{ $t(key) }}</v-tab
              >
            </v-tabs>
          </v-app-bar>
        </v-row>
        <div v-if="currentTab == 'tasks' && projectId" class="mt-4">
          <ProjectTask
            :project-detail="projectDetails"
            :project-id="projectId"
          />
        </div>
        <div v-if="currentTab == 'board'" class="mt-4">
          <KanbanBoard :project-details="projectDetails" />
        </div>
        <div v-if="currentTab == 'tickets'" class="mt-4">
          <ProjectTicketTable
            :project-flag="true"
            :project-details="projectDetails"
            @openAssociateTicketDialog="openAssociateTicketDialog"
            @onClickTicket="onClickTicket"
          />
        </div>
      </div>
      <v-dialog v-else v-model="dialog" fullscreen>
        <v-card>
          <div class="pt-2">
            <v-row no-gutters>
              <router-link :to="{ name: 'projects' }">
                <v-btn plain class="mt-2"
                  ><v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </router-link>
              <v-col class="d-flex justify-end">
                <v-btn plain width="50" min-width="40" @click="showUploadFiles"
                  ><v-icon>mdi-content-copy</v-icon></v-btn
                >
                <v-btn
                  v-if="checkPermission('editProject')"
                  plain
                  width="50"
                  min-width="40"
                  :disabled="projectDetails.status === 'Inactive'"
                  @click="onEditProjectClicked"
                  ><v-icon>mdi-pencil-outline</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-row no-gutters class="pl-5 pr-4">
              <span
                class="grey--text text--darken-4 text-h5 font-weight-medium mt-3"
                >{{ projectDetails.project_name }}</span
              >
              <v-col class="d-flex justify-end">
                <v-chip small class="mt-4 ml-3" color="info">
                  {{ projectDetails.status }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row no-gutters class="pl-9 pr-6 mt-4 pb-4">
              <v-col cols="12">
                <v-row
                  class="grey--text text--darken-4 text-h6 font-weight-medium"
                  >{{ projectDetails.progress }}%
                  <span class="grey--text text--darken-1 text-caption ml-3 mt-3"
                    >Completed</span
                  ></v-row
                >
                <v-row>
                  <v-progress-linear
                    height="12"
                    rounded
                    :value="projectDetails.progress"
                    color="green"
                    buffer-value="100"
                    bottom
                  ></v-progress-linear>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pa-0">
                    <v-card elevation="0">
                      <v-card-text class="pa-0">
                        <v-container class="pa-0 mt-4">
                          <v-row class="ml-1 my-2"
                            ><v-col
                              cols="5"
                              class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                              >{{ $t("projectName") }}:</v-col
                            ><v-col
                              class="pa-0 pr-4 grey--text text--darken-4 text-body-2 font-weight-medium"
                            >
                              {{ projectDetails.project_name }}</v-col
                            ></v-row
                          >
                          <v-row class="ml-1 my-2">
                            <v-col
                              cols="5"
                              class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                              >{{ $t("tasks") }}:</v-col
                            ><v-col
                              class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
                            >
                              {{ projectDetails.tasks }}</v-col
                            ></v-row
                          >
                          <v-row class="ml-1 my-2"
                            ><v-col
                              cols="5"
                              class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                              >{{ $t("projectOwner") }}:</v-col
                            ><v-col
                              class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
                            >
                              {{
                                projectOwner && projectOwner.name
                                  ? projectOwner.name
                                  : ""
                              }}</v-col
                            ></v-row
                          >
                          <v-row class="ml-1 my-2"
                            ><v-col
                              cols="5"
                              class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                              >{{ $t("createdOnText") }}:</v-col
                            ><v-col
                              class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
                            >
                              {{ formatDate(projectDetails.created_at) }}</v-col
                            ></v-row
                          >
                          <v-row class="ml-1 my-2"
                            ><v-col
                              cols="5"
                              class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                              >{{ $t("startDate") }}:</v-col
                            ><v-col
                              class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
                            >
                              {{ projectDetails.start_date }}</v-col
                            >
                          </v-row>
                          <v-row class="ml-1 my-2"
                            ><v-col
                              cols="5"
                              class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                              >{{ $t("latestFinishDate") }}:</v-col
                            ><v-col
                              class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
                            >
                              {{ projectDetails.finish_date }}</v-col
                            ></v-row
                          >
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>

            <v-row>
              <v-app-bar
                elevation="0"
                color="white"
                class="appbar-header"
                height="40"
              >
                <v-tabs v-model="currentTab">
                  <v-tab
                    v-for="key in tabs"
                    :key="key"
                    class="text-none"
                    :href="`#${key}`"
                    :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-5'"
                    >{{ $t(key) }}</v-tab
                  >
                </v-tabs>
              </v-app-bar>
            </v-row>
            <div v-if="currentTab == 'tasks' && projectId" class="mt-4">
              <ProjectTask
                :project-detail="projectDetails"
                :project-id="projectId"
              />
            </div>
            <div v-if="currentTab == 'board'" class="mt-4">
              <KanbanBoard :project-details="projectDetails" />
            </div>
            <div v-if="currentTab == 'tickets'" class="mt-4">
              <ProjectTicketTable
                :project-flag="true"
                :project-details="projectDetails"
                @openAssociateTicketDialog="openAssociateTicketDialog"
                @onClickTicket="onClickTicket"
              />
            </div>
          </div>
        </v-card>
      </v-dialog>
      <div
        v-if="$vuetify.breakpoint.mdAndUp && isUploadFiles"
        id="addProjectPanel"
      >
        <UploadFiles
          :reference-id="projectId"
          @hideUploadFiles="hideUploadFiles"
        />
      </div>
      <v-dialog v-else v-model="isUploadFiles" fullscreen>
        <UploadFiles
          :reference-id="projectId"
          @hideUploadFiles="hideUploassociateTicketDialogadFiles"
        />
      </v-dialog>
      <v-layout>
        <div
          v-if="$vuetify.breakpoint.mdAndUp && addProjectDialog"
          id="addProjectPanel"
        >
          <AddProject
            ref="addProject"
            :project-details="projectDetails"
            @hideProjectDialog="hideProjectDialog"
          />
        </div>
        <v-dialog
          v-if="!$vuetify.breakpoint.mdAndUp && addProjectDialog"
          v-model="addProjectDialog"
          fullscreen
        >
          <AddProject
            ref="addProject"
            :project-details="projectDetails"
            @hideProjectDialog="hideProjectDialog"
          />
        </v-dialog>
      </v-layout>
      <AssociateTicketDialog
        :project-details="projectDetails"
        :dialog="associateTicketDialog"
        @closeDialog="closeDialog"
        @associateTicketSubmit="associateTicketSubmit"
      />
    </div>
    <TicketDetails
      v-if="isTicketDetails"
      :ticket-details="TicketData"
      @hideTicketDetails="hideTicketDetails"
      @secondaryFnSuccessAlert="secondaryFnSuccessAlert"
      class="mt-4"
    />
  </div>
</template>

<script>
import UserDataMixin from "@/mixins/UserData";
import { bus } from "@/main";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadProjectDetails();
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath.startsWith("/projectDetails?pid=")) {
      const pid = to.fullPath.split("=")[1];
      this.projectId = pid;
      this.$route.query.pid = pid;
      this.loadProjectDetails();
    }
  },
  components: {
    AddProject: () => import("./AddProject.vue"),
    UploadFiles: () => import("./UploadFiles.vue"),
    ProjectTask: () => import("./ProjectTask.vue"),
    KanbanBoard: () => import("./KanbanBoard.vue"),
    ProjectTicketTable: () => import("./../ticket/ProjectTicketTable.vue"),
    AssociateTicketDialog: () => import("./AssociateTicketDialog"),
    TicketDetails: () => import("../ticket/TicketDetails.vue"),
  },
  props: {
    projectTab: {
      type: String,
      default: "",
    },
  },
  mixins: [UserDataMixin],
  data: () => ({
    currentTab: "Tasks",
    dialog: true,
    associateTicketDialog: false,
    isViewProjectDetails: false,
    addProjectDialog: false,
    isUploadFiles: false,
    projectDetails: {},
    projectId: "",
    tabs: ["tasks", "board", "tickets"],
    ticketAlertMessage: "",
    ticketSuccessAlert: "",
    isTicketDetails: false,
    TicketData: {},
  }),
  computed: {
    projectOwner() {
      const projOwner = this.$store.state.User.companyUsers.find((user) => {
        return user.user_id === this.projectDetails.project_manager_id;
      });
      return projOwner;
    },
  },
  created() {
    bus.$on("updateProjectDetails", this.fetchProjectDataById);
    this.currentTab = this.projectTab;
  },
  watch: {
    ticketSuccessAlert(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.ticketSuccessAlert = false;
        }, 8000);
      }
    },
  },
  methods: {
    fetchProjectDataFromStore() {
      let project = null;
      this.projectId = this.$route.query.pid;
      project = this.$store.state.Project.project.find((project) => {
        return project.project_id === this.projectId;
      });
      if (project) {
        this.formatProjectData(project);
      }
    },
    async fetchProjectDataById() {
      let project = null;
      this.projectId = this.$route.query.pid;
      let payload = {
        project_id: this.projectId,
      };
      const projectData = await this.$store.dispatch(
        "getProjectDataById",
        payload
      );
      project = projectData[0];
      if (project) {
        this.formatProjectData(project);
      }
    },
    formatProjectData(project) {
      project.start_date = this.formatDate(project.start_date);
      project.progress = project.no_of_task
        ? parseInt((project.no_of_completed_task / project.no_of_task) * 100)
        : 0;
      project.tasks = project.no_of_task ? project.no_of_task : 0;
      project.finish_date =
        project.max_task_end_date || project.max_task_start_date
          ? new Date(project.max_task_end_date) >
            new Date(project.max_task_start_date)
            ? this.formatDate(project.max_task_end_date)
            : this.formatDate(project.max_task_start_date)
          : "-";
      this.projectDetails = project;
    },
    closeTicketAlert() {
      this.ticketSuccessAlert = false;
    },
    onClickTicket(item) {
      this.isTicketDetails = true;
      this.TicketData = item;
      //this.$emit("onClickTicket", item);
    },
    hideUploassociateTicketDialogadFiles() {},
    closeDialog() {
      this.associateTicketDialog = false;
    },
    createTicket() {
      this.$store.commit("SET_LAST_ROUTE", this.$route.fullPath);
      this.$router.push("/createTicket").catch();
    },
    openAssociateTicketDialog() {
      this.associateTicketDialog = true;
    },
    associateTicketSubmit() {
      this.associateTicketDialog = false;
    },
    hideProjectDetails() {
      this.isViewProjectDetails = false;
    },
    hideProjectDialog() {
      this.addProjectDialog = false;
    },
    onEditProjectClicked() {
      this.addProjectDialog = true;
    },
    hideUploadFiles() {
      this.isUploadFiles = false;
    },
    showUploadFiles() {
      this.isUploadFiles = true;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("T")[0].split("-");
      return month ? `${month}/${day}/${year}` : date;
    },
    loadProjectDetails() {
      this.projectId = this.$route.query.pid;
      if (this.$store.state.Project.project.length) {
        this.fetchProjectDataFromStore();
      } else this.fetchProjectDataById();
    },
    secondaryFnSuccessAlert(ticketNumber, ticketRevision, type) {
      if (type === "cancelled") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision} has been cancelled!`;
      }
      if (type === "commented") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision}- comment added Successfully!`;
      }
      if (type === "retransmitted") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision} has been Retransmitted Successfully!`;
      }
      if (type === "repeat") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision} has been Repeated Successfully!`;
      }
      if (type === "3HourNotice") {
        this.ticketAlertMessage = `3-hour notice Ticket Created Successfully!`;
      }
      if (type === "extend") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision} has been Extended Successfully!`;
      }
      if (type === "secondNotice") {
        this.ticketAlertMessage = `Second notice Ticket Created Successfully!`;
      }
      if (type === "utilityNotFound") {
        this.ticketAlertMessage = `Utility Not Found Ticket Created Successfully!`;
      }
      if (type === "addMembers") {
        this.ticketAlertMessage = `Members added Successfully!`;
      }
      if (type === "relocateRefresh") {
        this.ticketAlertMessage = `Ticket No. ${ticketNumber}-${ticketRevision} has been Relocate Refresh Successfully!`;
      }
      if (type === "noResponse") {
        this.ticketAlertMessage = `No response ticket has been created Successfully! `;
      }
      this.ticketSuccessAlert = true;
      this.hideTicketDetails();
    },
    hideTicketDetails() {
      this.TicketData = {};
      this.isTicketDetails = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/settings.scss";
.appbar-header.white {
  border: 1px solid $grey-lighten-2 !important;
}

#addProjectPanel {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform-origin: center center;
  max-width: 560px;
  margin-right: 0px;
  margin-top: -60px;
  margin-left: 0px;
  margin-bottom: -20px;
  z-index: 100;
  background-color: #fff;
  border-left: 1px solid #e0e0e0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;
}
</style>
